<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.$t("device.ads.text"),
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "",
      items: [],
      stockinfo: [],
      devicesearch: "",
      getdeviceslist: "",
      selectdevicestatus: "-1",
      
      ismobile: false,
      activeNames: [],
      activeapp: [],
      showmodel: "1",
      userinfo: [],
      deviceid: 0,
    };
  },
  mounted() {
    this.title = this.$t("device.ads.text");
    this.items = [
      {
        text: this.$t("menuitems.devices.text"),
        href: "/",
      },
      {
        text: this.$t("menuitems.devices.lists"),
        href: "/device/list",
      },
      {
        text: this.$t("menuitems.devices.devicedetail"),
        href: "/device/devicedetail?id=" + this.$route.query.id,
      },
      {
        text: this.$t("device.ads.text"),
        active: true,
      },
    ];
    //   this.titleTemplate(this.$t('menuitems.devices.lists'))
    
    
    this.userinfo = JSON.parse(localStorage.getItem("user"));
    this.getstockinfo();
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      this.ismobile = true;
    }
    this.deviceid = this.$route.query.id;
    //   this.page.title=this.$t('menuitems.devices.lists')
    console.log(this.$t('device.stock.notice'))
  },
  methods: {
    getstockinfo() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getstocksetting",
            id: that.$route.query.id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.stockinfo = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    resetstock(id){

    },
    resetstockv2(id){

    },
    changestock(id,save){

    },
    changenoticevalue(id,save){

    }
  },
  filters: {
      formatTimer: function(value) {
        value=value*1000
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? "0" + MM : MM;
        let d = date.getDate();
        d = d < 10 ? "0" + d : d;
        let h = date.getHours();
        h = h < 10 ? "0" + h : h;
        let m = date.getMinutes();
        m = m < 10 ? "0" + m : m;
        let s = date.getSeconds();
        s = s < 10 ? "0" + s : s;
        return y + "-" + MM + "-" + d + " " + h + ":" + m;
      }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>